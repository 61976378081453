<template>
  <div class="appsEvents">
    <el-button
      class="apps-add-btn"
      type="primary"
      @click="handleEditAdd(null)"
    >
      Создать
    </el-button>
    <Table
      :table-data="tableData"
      :column="tableColumns"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-edit"
              @click.native="handleEditAdd(scope.row)"
            >
              Редактировать
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="handleDelete(scope.row.id)"
            >
              Удалить
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <EventsModal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="openModal=false"
    />
  </div>
</template>

<script>
import {
  GETTER_APP_EVENTS_TABLE_COLUMNS,
  ACTION_APPS_GET_EVENTS,
  GETTER_APP_EVENTS_TABLE_DATA, ACTION_APPS_EVENT_DELETE,
} from '@/const';
import { mapGetters, mapActions } from 'vuex';
import Table from '../../components/Table.vue';
import EventsModal from '../Apps/EventsModal';

export default {
  components: {
    Table,
    EventsModal,
  },

  data () {
    return {
      openModal: false,
      formData: {},
      loading: false,
    };
  },
  
  computed: {
    ...mapGetters({
      tableColumns: GETTER_APP_EVENTS_TABLE_COLUMNS,
      tableData: GETTER_APP_EVENTS_TABLE_DATA,
    }),
  },

  async created () {
    await this.getNewPage();
  },

  methods: {
    ...mapActions({
      getEvents: ACTION_APPS_GET_EVENTS,
      deleteEvent: ACTION_APPS_EVENT_DELETE,
    }),

    async getNewPage (page=1) {
      this.loading = true;
      await this.getEvents({
        query: { page },
        params: { id: this.$route.params.id },
      });
      this.loading = false;
    },

    mouseOver (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    handleEditAdd (row) {
      this.openModal = true;
      this.formData = row;
    },
    async handleDelete (id) {
      const { currentPage, rows } = this.tableData;
      this.$messageBox.confirm(
        'Вы действительно хотите удалить', '', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
        }).then(async () => {
        await this.deleteEvent({
          params: {
            id,
            app_id: this.$route.params.id,
          },
        });

        const page = rows.length - 1 ? currentPage : currentPage - 1;
        await this.getNewPage(page);
      }).catch(() => {});
    },
  },

};

</script>

<style lang="scss">
.appsEvents {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>