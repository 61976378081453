<template>
  <div class="sistem-report">
    <el-form
      :inline="true"
      :model="form"
      label-position="top"
      class="sistem-report-form"
    >
      <el-form-item 
        label="Выберите отчет"
      >
        <el-select
          v-model="selected"
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in filter.reports"
            :key="item"
            :label="item"
            :value="item"
            @click.native="selectedItem(item)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Выберите диапазон дат">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="До"
          start-placeholder="Дата начала"
          end-placeholder="Дата окончания" 
        />
      </el-form-item>
      <el-form-item class="apply-btn">
        <el-button
          :disabled="!selected"
          type="primary"
          @click="onSubmit"
        >
          Применить
        </el-button>
      </el-form-item>
    </el-form>
    <ReportTable
      :report-data="getCurrentReport"
      @goToPage="getNewPage"
    />
  </div>
</template>

<script>
import {
  GETTER_SYSTEM_REPORT,
  ACTION_SYSTEM_CURRENT_REPORT_GET,
} from '@/const';
import moment from 'moment';
import ReportTable from '@/components/ReportTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SystemReports',
  components: {
    ReportTable,
  },
  data () {
    return {
      filter: {
        date: moment(),
        reports: [
          'Админы и менеджеры',
          'Агентства',
          'Рекламодатели',
          'Издатели',
        ],
      },
      selected: '',
      form: {
        date: [moment().subtract(1, 'month').format('YYYY, MM, DD'), moment().format('YYYY, MM, DD')],
        report: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentReport: GETTER_SYSTEM_REPORT,
    }),
  },
  async created () {
    await this.fetchCurrentReport({
      'report-name': 'report_daily',
      'between[from]': this.form.date[0],
      'between[to]': this.form.date[1],
    });
  },
  methods: {
    ...mapActions({
      fetchCurrentReport: ACTION_SYSTEM_CURRENT_REPORT_GET,
    }),

    async onSubmit () {
      await this.fetchCurrentReport({
        params: { 'report-name': this.form.report },
        query: {
          'between[from]': moment(this.form.date[0]).format('YYYY-MM-DD'),
          'between[to]': moment(this.form.date[1]).format('YYYY-MM-DD'),
          format: 'json',
        },
      });
    },

    async getNewPage (page) {
      await this.fetchCurrentReport({
        params: { 'report-name': this.form.report },
        query: { 
          date_from: this.form.date[0],
          date_to: this.form.date[1],
          page,
          format: 'json',
        },
      });
    },

    selectedItem (item) {
      switch (item) {
      case 'Админы и менеджеры':
        this.form.report = 'report_daily';
        break;
      case 'Издатели':
        this.form.report = 'report_apps';
        break;
      case 'Агентства':
        this.form.report = 'report_campaigns';
        break;
      case 'Рекламодатели':
        this.form.report = 'report_campaigns';
        break;
      }
    },
  },
};
</script>

<style lang="scss">
.sistem-report {
 width: 100%;
 position: relative;
 padding-top: 50px;

  .sistem-report-form{
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    top: 50px;
    position: absolute;
    right: 200px;
    z-index: 2;
  }

  &-form {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: 41px;
    right: 200px;
    z-index: 2;
    .el-form-item {
      margin-right: 10px;
      text-align: left;
      &__label {
        line-height: 0;
      }
    }
  }
}
</style>