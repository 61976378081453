<template>
  <div class="publishers">
    <el-button
      class="publishers-add-btn"
      type="primary"
      @click="openDialog(null)"
    >
      Создать
    </el-button>
    <Table
      :table-data="getPublishersData"
      :column="getPublishersColumns"
      :cellstyle="cellStyle"
      :loading="loading"
      @cellStatus="cellUser"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown
          :ref="'dropdown' + scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo(scope.row.id)"
            >
              Просмотр списка пользователей
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-edit"
              @click.native="openDialog(scope.row.id)"
            > 
              Редактировать
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="deletePublisher(scope.row.id)"
            > 
              Удалить
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <PublisherModal
      v-if="dialogVisible"
      :id="id"
      :dialog-visible="dialogVisible"
      @newPage="getNewPage"
      @close="close"
    />
  </div>
</template>

<script>

import { GETTER_PUBLISHERS_DATA, GETTER_PUBLISHERS_TABLE, ACTION_PUBLISHERS_REMOVE, ACTION_PUBLISHERS_GET } from '@/const';
import { mapActions, mapGetters } from 'vuex';
import PublisherModal from './PublisherModal.vue';
import Table from '@/components/Table.vue';

export default {
  name: 'Agencies',
  components: {
    PublisherModal,
    Table,
  },
  data () {
    return {
      dialogVisible: false,
      id: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_DATA,
      getPublishersColumns: GETTER_PUBLISHERS_TABLE,
    }),

    cellStyle () {
      return {
        columnProperty: 'usersCount',
        styles: 'cursor: pointer; color: #409EFF',
      };
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
      removePublishers: ACTION_PUBLISHERS_REMOVE,
    }),

    async getNewPage (page=1) {
      this.loading = true;
      await this.fetchAllPublishers( {
        query: { page },
      });
      this.loading = false;
    },

    close () {
      this.dialogVisible = false;
    },

    openDialog (id) {
      this.id = id;
      this.dialogVisible = true;
    },

    async deletePublisher (id) {
      const { currentPage, rows } = this.getPublishersData;
      this.$messageBox.confirm(
        'Вы действительно хотите удалить', '', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
        }).then(async () => {
        await this.removePublishers({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      });
    },

    cellUser (row, col) {
      if (col.property === 'usersCount') {
        this.routeTo(row.id);
      }
      if (col.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    mouseOver (row, column, cell) {
      if (column.property === 'usersCount' || column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'usersCount' || column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    routeTo (publishersId) {
      this.$router.push({ name: 'PublishersUsers', params: { id: publishersId } });
    },
  },
};

</script>

<style lang="scss">
.publishers {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>