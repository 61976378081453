import {
  URL_USERS_GET_CREATE,
  MUTATION_USERS_SET,
  ACTION_ROOT_REQUEST,
  URL_USERS_ACTIVATE,
  URL_USERS_BLOCK,
  URL_USERS_DELETE_CURRENT,
  URL_USERS_UPDATE,
  URL_USERS_GET,
} from '@/const';

const state = {
  usersData: {
    rows: [],
  },
};

const getters = {

  getUsersColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 35,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Статус',
        width: 35,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'email',
        label: 'Почта',
        width: 100,
      },
      {
        prop: 'role',
        label: 'Роль',
        width: 100,
      },
      {
        prop: 'publishers',
        label: 'Издатель',
        width: 100,
        buttons: true,
      },
    ];
    return columns;
  },

  getUsersData (state) {
    return state.usersData;
  },
};

const mutations = {
  setUsers (state, users) {
    if (users.rows) {
      users.rows = users.rows.map(item => {
        const publishersNames = item.publishers.map(pub => pub.name).join(',\n');
        item.publishers = publishersNames || 'Нет данных';
        return item;
      });
    }

    state.usersData = users;
  },
};

const actions = {
  getAllUsers: async ({ dispatch }, { query={} }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_USERS_GET_CREATE,
      commitName: MUTATION_USERS_SET,
    }, { root: true });
    return res;
  },

  getUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_USERS_GET,
    }, { root: true });
    return res;
  },

  createUser: async ({ dispatch }, { data }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_USERS_GET_CREATE,
    }, { root: true });
    return res;
  },

  updateUser: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_USERS_UPDATE,
    }, { root: true });
    return res;
  },

  deleteUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_USERS_DELETE_CURRENT,
    }, { root: true });
    return res;
  },

  activateUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_USERS_ACTIVATE,
    }, { root: true });
    return res;
  },

  blockUser: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_USERS_BLOCK,
    }, { root: true });
    return res;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};