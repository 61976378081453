import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '../views/NotFound';
import Login from '../views/Authentication/Login.vue';
import Restore from '../views/Authentication/Restore.vue';
import Verify from '../views/Authentication/Verify';
import Edit from '../views/Authentication/Edit';
import SystemReport from '../views/SystemReports';
import Apps from '../views/Apps/index';
import AppsReport from '../views/Apps/AppsReport';
import Publishers from '../views/Settings/_Publishers';
import Users from '../views/Settings/_Users';
import PublishersUsers from '../views/Settings/_Publishers/PublishersUsers.vue';

import AppsEvents from '@/views/Apps/AppsEvents';
import Basic from '@/layout/Basic';
Vue.use(VueRouter);


const routes = [

  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Авторизация',
      accessUnAuth: true,
    },
    component: Login,
  },
  {
    path: '/restore',
    name: 'Restore',
    meta: {
      title: 'Востановление пароля',
      accessUnAuth: true,
    },
    component: Restore,
  },
  {
    path: '/confirm-email',
    name: 'Verify',
    meta: {
      title: 'Подтверждение пароля',
      accessUnAuth: true,
    },
    component: Verify,
  },

  {
    path: '/',
    name: 'Basic',
    component: Basic,
    children: [
      {
        path: '/apps/:id/report',
        name: 'AppsReport',
        meta: {
          title: 'Отчёты Приложении',
        },
        component: AppsReport,
      },
      {
        path: '/apps/:id/events',
        name: 'AppsEvents',
        meta: {
          title: 'События Приложении',
        },
        component: AppsEvents,
      },
      {
        path: '/apps',
        name: 'Apps',
        meta: {
          title: 'Приложения',
        },
        component: Apps,
      },
      {
        path: '/users',
        name: 'Users',
        meta: {
          title: 'Пользователи',
          parent: 'Settings',
        },
        component: Users,
      },
      {
        path: '/publishers',
        name: 'Publishers',
        meta: {
          title: 'Издатели',
          parent: 'Settings',
        },
        component: Publishers,
      },
      {
        path: '/publishers/:id/users',
        name: 'PublishersUsers',
        meta: {
          title: 'Пользователи Издателя',
          parent: 'Settings',
        },
        component: PublishersUsers,
      },
      {
        path: '/profile',
        name: 'Profile',
        meta: {
          title: 'Профиль',
        },
        component: Edit,
      },
      {
        path: '/reports',
        name: 'Report',
        meta: {
          title: 'Отчёты',
          access: ['admin', 'manager'],
        },
        component: SystemReport,
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Страницы нет!',
    },
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  document.title = 'DMP | ' + to.meta.title;
  if (!localStorage.getItem('token') && !to.meta.accessUnAuth ) {
    router.push({ name: 'Login' });
  } else next();
});

export default router;
