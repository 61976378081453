<template>
  <el-container> 
    <el-dialog
      title="Добавление пользователя"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form
        v-loading="loading"
        style="position: inherit!important;"
        name="user" 
        class="demo-modalForm"
      >
        <el-form-item
          label="Email Пользователей"
        >
          <el-input 
            v-model="users" 
            type="textarea"
            :rows="3"
            placeholder="Введите почты пользователей"
          />
        </el-form-item>
        <el-form-item
          class="item-flex"
        >
          <el-button
            type="primary"
            @click.prevent="addUsers"
          >
            Добавить
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_PUBLISHERS_USERS_POST,
  GETTER_PUBLISHERS_USERS_DATA,
} from '@/const';

export default {
  name: 'AgenciesUsersModal',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      users: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_USERS_DATA,
    }),
  },

  methods: {
    ...mapActions({
      addNewPublishersUsers: ACTION_PUBLISHERS_USERS_POST,
    }),
    
    async addUsers () {
      await this.addNewPublishersUsers ({
        params: { id: this.$route.params.id },
        data: { emails: this.users.split('\n') },
      });
      this.$emit('close');
    },
  },
};
</script>

<style>

</style>