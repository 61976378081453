<template>
  <el-dialog
    :title=" formData ? 'Обновление События Приложения' : 'Cоздание События Приложения'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="Loading"
      :model="form"
      :rules="rules"
      style="position: inherit!important;"
    >
      <el-form-item
        label="Название"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Ключ"
        prop="key"
      >
        <el-input
          v-model="form.key"
        />
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="updateAppsEvent"
        >
          Сохранить
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createAppsEvent"
        >
          Создать
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GETTER_APP_EVENTS_TABLE_DATA,
  ACTION_APPS_EVENT_CREATE,
  ACTION_APPS_GET_EVENTS,
  ACTION_APPS_EVENT_UPDATE,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      Loading: false,
      form: {
        name: '',
        key: '',
      },
      rules: {
        key: [
          { required: true, message: 'Заполните поле' },
        ],
        name: [
          { required: true, message: 'Заполните поле' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      getTableData: GETTER_APP_EVENTS_TABLE_DATA,
    }),
  },

  created () {
    if (this.formData) {
      this.form = { ...this.formData };
    }
  },

  methods: {
    ...mapActions({
      updateEvent: ACTION_APPS_EVENT_UPDATE,
      createEvent: ACTION_APPS_EVENT_CREATE,
      getAppsEvents: ACTION_APPS_GET_EVENTS,
    }),

    async updateAppsEvent () {
      this.Loading = true;
      const data = { ...this.form };

      const response = await this.updateEvent({
        params: {
          id: this.formData.id,
          app_id: this.$route.params.id,
        },
        data,
      });

      await this.check(response);

      this.Loading = false;
    },

    async createAppsEvent () {
      await this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.Loading = true;
          const data = { ...this.form };
 
        
          const response = await this.createEvent({
            params: { app_id: this.$route.params.id },
            data,
          });

          await this.check(response);
          this.Loading = false;
        }});
    },

    async check (response) {
      const { currentPage } = this.getTableData;

      if (response.errors) {
        await this.$messageBox.alert(
          'Что-то пошло не так', 'Ошибка', {
            confirmButtonText: 'Ok',
          });
      } else {
        await this.getAppsEvents({
          query: { page: currentPage },
          params: { id: this.$route.params.id },
        });
        this.$emit('close');
      }
    },
  },
};

</script>

<style lang="scss">
.el-form {
  overflow: hidden;
  max-height: 450px;
  padding-right: 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}
</style>