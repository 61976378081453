import {
  ACTION_ROOT_REQUEST,
  URL_PUBLISHERS_GET_POST,
  URL_PUBLISHERS_DELETE_PUT,
  URL_PUBLISHERS_USERS_GET_POST,
  URL_PUBLISHERS_USERS_DELETE,
  MUTATION_PUBLISHERS_SET,
  MUTATION_PUBLISHERS_USERS_SET,
} from '@/const';

const state = {
  publishersData: {
    rows: [],
    page: null,
    limitPerPage: null,
  },
  publishersUsers: {},
};

const getters = {
  getPublishersData (state) {
    state.publishersData.rows = state.publishersData.rows.map(item => {
      const usersCount = Object.keys(item.users).length;
      return {
        ...item,
        ownerName: item.owner.email,
        usersCount,
      };
    });

    return state.publishersData;
  },

  getPublishersUser (state) {
    return state.publishersUsers;
  },

  getPublishersColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'name',
        label: 'Название',
        width: 100,
      },
      {
        prop: 'ownerName',
        label: 'Владелец',
        width: 100,
      },
      {
        prop: 'usersCount',
        label: 'Пользователи',
        width: 100,
      },
    ];
    return columns;
  },

  getPublishersUsersColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 40,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 35,
      },
      {
        prop: 'email',
        label: 'Почта',
        width: 170,
      },
    ];
    return columns;
  },
};

const mutations = {
  setPublishers (state, data) {
    state.publishersData = data;
  },

  setPublishersUser (state, data) {
    state.publishersUsers = data;
    state.publishersUsers.rows = Object.values(data.rows);
  },
};

const actions = {
  fetchAllPublishers: async ({ dispatch }, { query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_PUBLISHERS_GET_POST,
      commitName: MUTATION_PUBLISHERS_SET,
    }, { root: true });
    return response;
  },

  fetchPublisher: async ({ dispatch }, { params }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_PUBLISHERS_DELETE_PUT,
    }, { root: true });
    return response;
  },

  fetchAllUsers: async ({ dispatch }, { params }) => {
    const data = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_PUBLISHERS_USERS_GET_POST,
      commitName: MUTATION_PUBLISHERS_USERS_SET,
    }, { root: true });
    return data;
  },

  removePublishers: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_PUBLISHERS_DELETE_PUT,
    }, { root: true });
  },

  updatePublishers: async ({ dispatch }, { data, params }) => {
    return await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_PUBLISHERS_DELETE_PUT,
    }, { root: true });
  },

  createPublishers: async ({ dispatch }, { data }) => {
    return await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_PUBLISHERS_GET_POST,
    }, { root: true });
  },

  addNewPublishersUsers: async ({ dispatch }, { data, params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_PUBLISHERS_USERS_GET_POST,
    }, { root: true });
  },

  removePublishersUsers: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_PUBLISHERS_USERS_DELETE,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
