<template>
  <el-container>
    <div class="login-block">
      <h3 class="login-title">
        Login
      </h3>
      <el-form
        ref="form"
        v-loading="load"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="Email"
          prop="email"
          :rules="rules.email"
        >
          <el-input
            v-model="form.email"
            :class="{ 'input-error': isEmailError }"
            :placeholder="'Введите Email'"
            type="email"
            autocomplete="off"
            @focus="isEmailError = false"
          />
          <span
            v-if="isEmailError"
            class="errorMsg"
          >
            {{ emailErrorMsg }}
          </span>
        </el-form-item>
        <el-form-item
          label="Пароль"
          prop="password"
          :rules="rules.password"
        >
          <el-input
            v-model="form.password"
            :class="{ 'input-error': isPassError }"
            :placeholder="'Введите пароль'"
            autocomplete="off"
            type="password"
            @focus="isPassError = false"
          />
          <span
            v-if="isPassError"
            class="errorMsg"
          >
            {{ passErrorMsg }}
          </span>
        </el-form-item>
        <el-form-item class="btn-block">
          <el-button
            type="primary"
            @click="submitForm"
          >
            Войти
          </el-button>
          <el-button
            @click="resetForm"
          >
            Очистить
          </el-button>
        </el-form-item>
      </el-form>
      <div class="btn-block">
        <el-button
          type="text"
          @click="openDialog"
        >
          Забыли пароль?
        </el-button>
      </div>
    </div>
    <el-dialog
      title="Восстановление пароля"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <div
        v-loading="load"
        element-loading-background="transparent"
      >
        <el-form
          ref="modalForm"
          :model="modal"
          :rules="modalRules"          
          status-icon
          @submit.stop.prevent="sendModalEmail"
        >
          <el-form-item
            prop="email"
            :rules="modalRules.email"
            label="Email"
          >
            <el-input
              v-model="modal.email"
              autocomplete="off"
              type="email"
              placeholder="'Введите почту для восстановления'"
            />
          </el-form-item>
          <el-form-item class="btn-center">
            <el-button
              type="primary"
              @click="sendModalEmail"
            >
              Отправить
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTION_AUTH_LOGIN, ACTION_AUTH_EMAIL } from '@/const';

export default {
  components: { },
  data () {
    const checkPassLength = (rule, value, cb) => value.length < 5 ? cb(new Error('Мин. длина пароля: 5')) : cb();

    return {
      isPassError: false,
      isEmailError: false,
      passErrorMsg: null,
      emailErrorMsg: null,
      form: {
        email: null,
        password: null,
      },
      load: false,
      modal: {
        email: null,
      },
      modalRules: {
        email: [
          { required: true, message: 'Заполните поле', trigger: ['change', 'blur'] },
          { type: 'email', message: 'Пожалуйста введите правильную почту', trigger: ['change', 'blur'] },
        ],
      },
      rules: {
        email: [
          { required: true, message: 'Заполните поле', trigger: ['change', 'blur'] },
          { type: 'email', message: 'Пожалуйста введите правильную почту', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, message: 'Заполните поле', trigger: ['change', 'blur'] },
          { validator: checkPassLength, trigger: ['blur', 'change'] },
        ],
      },
      // TODO
      errorModalText: '',
      dialogVisible: false,
    };
  },
  methods: {
    ...mapActions({
      loginData: ACTION_AUTH_LOGIN,
      sendEmail: ACTION_AUTH_EMAIL,
    }),

    async submitForm () {
      await this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.load = true;
          const response = await this.loginData({
            data: { email: this.form.email, password: this.form.password },
          });
          // TODO исправить костыль
          if (response) {
            if (response.status === 423) {
              response.data.errors.forEach(text => {
                this.emailErrorMsg = text;
              });
              this.isEmailError = true;
            }
            if (response.status === 200) {
              await this.$router.push({ name: 'Basic' });
            } else if (response.status === 404) {
              response.data.errors.forEach(errorText => {
                if ('Неверный пароль' === errorText) {
                  this.passErrorMsg = errorText;
                  this.isPassError = true;

                } else if ('Пользователь не найден' === errorText) {
                  this.emailErrorMsg = errorText;
                  this.isEmailError = true;
                }
              });
            }
          } else {
            this.$messageBox.alert('Внутренняя ошибка!', '', {
              type: 'error',
              confirmButtonText: 'OK',
            });
          }
          this.load = false;
        }
      });
    },
    resetForm () {
      this.form.email = this.form.password = null;
    },
    openDialog () {
      this.dialogVisible = true;
    },

    async sendModalEmail () {
      
      await this.$refs['modalForm'].validate( async (valid) => {
        if (valid) {
          this.load = true;
          const response = await this.sendEmail({
            query: { email: this.modal.email },
          });
          if (response.data.errors) {
            this.load = false;
            response.data.errors.forEach(item => {
              this.errorModalText = item.msg;
            });
          }
          this.$messageBox.alert(
            !response.data.errors
              ? 'На вашу почту отправлено письмо с инструкциями по восстановлению пароля'
              : this.errorModalText, '', {
              type: !response.data.errors ? 'success' : 'error',
              confirmButtonText: 'OK',
              callback: () => {
                this.modal.email = '';
                if (!response.data.errors) {
                  this.dialogVisible = false;
                }
              },
            });
          this.load = false;
        }
      } );
    },
  },
};
</script>

<style lang="scss">
.login-block {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(28, 24, 54, 0.12), 0 0 6px rgba(26, 34, 59, 0.04);

  .el-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .input-error {
      border: 1px solid red;
      border-radius: 5px;
      max-height: 40px;
    }

    & .errorMsg {
      position: absolute;
      top: 103%;
      font-size: 13px;
      left: 10px;
      color: red;
      line-height: 1;
    }
  }

  & .login-title {
    text-align: center;
    letter-spacing: 2px;
    color: #606266;
    text-transform: uppercase;
  }

  & .btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .form-subtitle {
    color: #409EFF;
  }

  & .btn-block {
    display: flex;
    justify-content: space-between;
  }
}
.loader {
  width: 100%;
}
.btn-center {
  display: flex;
  justify-content: flex-end;
}
</style>
