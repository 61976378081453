<template>
  <div class="users">
    <span>
      <FilterForm
        :parent-name="'users'"
        :autocompletes-array="users"
        @search="searchUsers"
        @reset="resetFilter"
      />
      <el-button
        class="formCreateBtn"
        type="primary"
        @click="openDialog(null)"
      >
        Создать
      </el-button>
    </span>

    <Table
      :table-data="tableData"
      :column="getUsersColumns"
      :loading="loading"
      @newPage="getNewPage"
      @cellStatus="cellClick"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
    >
      <template
        v-slot="{ scope }"
      >
        <p
          v-if="scope.column.property === 'publishers'"
          style="margin: 0; white-space: pre"
        >
          {{ scope.row.publishers }}
        </p>
        <el-dropdown
          v-else
          :ref="'dropdown' + scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              icon="el-icon-edit"
              @click.native="openDialog(scope.row.id)"
            > 
              Редактировать
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="removeUser(scope.row.id)"
            > 
              Удалить
            </el-dropdown-item>
            <el-dropdown-item
              v-if="scope.row.status !== 'active'"
              icon="el-icon-unlock"
              @click.native="activateUserBtn(scope.row.id)"
            > 
              Активировать
            </el-dropdown-item>
            <el-dropdown-item
              v-if="scope.row.status === 'active'"
              icon="el-icon-lock"
              @click.native="blockUserBtn(scope.row.id)"
            > 
              Блокировать
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <UserModal
      v-if="openModal"
      :open-modal="openModal"
      :user-id="userId"
      :current-page="currentPage"
      @close="openModal=false"
      @newPage="getNewPage"
    />
  </div>
</template>

<script>

import UserModal from './UserModal';
import {
  GETTER_USERS_DATA,
  GETTER_USERS_TABLE,
  GETTER_AUTH_CURRENT_USER,
  ACTION_USERS_GET_USERS,
  ACTION_USERS_DELETE,
  ACTION_USERS_BLOCK,
  ACTION_USERS_ACTIVATE,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/Table.vue';
import FilterForm from '../../../components/FilterForm.vue';

export default {
  components: {
    UserModal,
    Table,
    FilterForm,
  },
  data () {
    return {
      openModal: false,
      userId: null,
      currentPage: null,
      loading: false,
      isFiltered: false,
    };
  },
  computed: {
    ...mapGetters({
      getUsersData: GETTER_USERS_DATA,
      getUsersColumns: GETTER_USERS_TABLE,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),

    users () {
      return this.getUsersData.rows.map(user => {
        return {
          id: user.id,
          value: user.id + ' - ' + user.email,
        };
      });
    },

    tableData () {
      const data = { ...this.getUsersData };

      if (data.rows) {
        data.rows = data.rows.filter(item => {
          return item.id !== this.currentUser.id;
        });
      }
      return data;
    },
  },

  created () {
    this.getNewPage();
  },

  methods: {
    ...mapActions({
      getAllUsers: ACTION_USERS_GET_USERS,
      deleteUser: ACTION_USERS_DELETE,
      activateUser: ACTION_USERS_ACTIVATE,
      blockUser: ACTION_USERS_BLOCK,
    }),

    async searchUsers (data) {
      this.isFiltered = true;
      const query = {
        query: data.text,
      };

      await this.getAllUsers({
        query,
      });
    },

    async resetFilter () {
      if (this.isFiltered) {
        this.isFiltered = false;
        await this.getNewPage();
      }
    },

    async removeUser (id) {
      const { currentPage, rows } = this.getUsersData;
      this.$messageBox.confirm(
        'Вы действительно хотите удалить', '', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
        }).then(async () => {         
        await this.deleteUser({
          params: { id },
        });
        if (rows.length - 1) {
          this.getNewPage(currentPage);
        } else {
          this.getNewPage(currentPage - 1);
        }
      });
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    async activateUserBtn (id) {
      const response = await this.activateUser({
        params: { id },
      });
      if (response.data.activated) {
        this.getNewPage(this.currentPage);
      }
    },

    async blockUserBtn (id) {
      const response = await this.blockUser({
        params: { id },
      });
      if (response.data.blocked) {
        this.getNewPage(this.currentPage);
      }
    },

    async getNewPage (page = 1) {
      this.loading = true;
      this.currentPage = page;
      await this.getAllUsers({
        query: { page },
      });
      this.loading = false;
    },

    mouseOver (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    openDialog (id) {
      this.userId = id;
      this.openModal = true;
    },
  },
};

</script>

<style lang="scss">
.users {
  position: relative;
  width: 100%;
  text-align: right;
  padding: 15px;

  .formCreateBtn{
    margin-top: 40px;
  }
  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
  .user-filter-form {
    position: absolute;
    top: 40px;
    right: 0;
    text-align: left;
    margin-bottom: 40px;

    & .el-form-item__label {
      line-height: 0;
    }

  }
  .btns {
    margin-top: 20px;
  }
  .apps-add-btn {
    margin-left: 50px;
  }
}
.el-popover {
  min-width: 250px !important;
  max-width: 400px;
  max-height: 200px;
  right: 50px;
  overflow: auto;
  white-space: pre-wrap;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
  }

  &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
  }
}
</style>