<template>
  <div
    v-loading="load"
    class="verify"
    element-loading-background="transparent"
  />
</template>

<script>
import { ACTION_AUTH_VERIFY } from '@/const';
import { mapActions } from 'vuex';
export default {
  data () {
    return {
      load: true,
    };
  },
  async created () {
    const res = await this.verify({
      query: { key: this.$route.query.key },
    });
    this.load = false;
    this.openModal(res.status);
  },
  methods: {
    ...mapActions({
      verify: ACTION_AUTH_VERIFY,
    }),
    openModal (status) {
      const text = status === 200 ? 'Адрес электронной почты подтверждён, вы можете авторизоваться' : '';
      this.$messageBox.alert(
        text, '', {
          type: status === 200 ? 'success': 'error',
          confirmButtonText: 'OK',
          callback: () => {
            this.$router.push('/login');
          },
        });
    },
  },
};
</script>

<style lang="scss">
.verify {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  & .el-message-box__headerbtn {
    display: none;
  }
}

</style>