<template>
  <el-container>
    <h1>
      404 Страница не найдена
    </h1>
  </el-container>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.el-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  & h1 {
    color: #66b1ff;
    font-family: fantasy;
    font-weight: 500;
  }
}
</style>