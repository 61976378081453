<template>
  <div class="apps">
    <span>
      <FilterForm
        :parent-name="'apps'"
        :autocompletes-array="publishers"
        @search="searchApps"
        @reset="getNewPage"
      />

      <el-button
        class="formCreateBtn"
        type="primary"
        @click="handleEditAdd(null)"
      >
        Создать
      </el-button>
    </span>

    <Table
      :table-data="tableData"
      :column="column"
      :loading="loading"
      @newPage="getNewPage"
      @mouseOver="mouseOver"
      @mouseOut="mouseOut"
      @cellStatus="cellClick"
    >
      <template
        v-slot="{ scope }"
      >
        <el-dropdown 
          :ref="'dropdown'+scope.row.id"
          trigger="click"
        >
          <i
            class="el-icon-more"
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="routeTo('Report', scope.row.id)"
            >
              Отчеты
            </el-dropdown-item>
            <el-dropdown-item
              @click.native="routeTo('Events', scope.row.id)"
            >
              События
            </el-dropdown-item>
            <el-dropdown-item
              divided
              icon="el-icon-edit"
              @click.native="handleEditAdd(scope.row)"
            >
              Редактировать
            </el-dropdown-item>
            <el-dropdown-item
              icon="el-icon-delete"
              @click.native="handleDelete(scope.row.id)"
            >
              Удалить
            </el-dropdown-item>
            <el-dropdown-item
              v-if="['admin', 'manager'].includes(currentUser.role) "
              :disabled="currentUser.role === 'client' && scope.row.status === 'blocked'"
              :icon="getStatusClass(scope.row)"
              @click.native="handleChangeStatus(scope.row)"
            >
              {{ getStatusType(scope.row) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <Modal
      v-if="openModal"
      :open-modal="openModal"
      :form-data="formData"
      @close="openModal=false"
    />
  </div>
</template>

<script>
import Modal from '../Apps/Modal.vue';
import {
  GETTER_APP_TABLE_COLUMNS,
  GETTER_APP_TABLE_DATA,
  ACTION_APPS_GET,
  GETTER_AUTH_CURRENT_USER,
  ACTION_APPS_CHANGE_ACTIVATE,
  ACTION_APPS_DELETE,
  ACTION_APPS_CHANGE_BLOCK,
  ACTION_PUBLISHERS_GET,
  GETTER_AUTH_USER_ROLE,
  GETTER_PUBLISHERS_DATA,
} from '@/const';
import { mapGetters, mapActions } from 'vuex';
import Table from '../../components/Table.vue';
import FilterForm from '../../components/FilterForm.vue';

export default {
  components: {
    Modal,
    Table,
    FilterForm,
  },

  data () {
    return {
      openModal: false,
      column: null,
      formData: {},
      loading: false,
    };
  },
  
  computed: {
    ...mapGetters({
      tableColumns: GETTER_APP_TABLE_COLUMNS,
      tableData: GETTER_APP_TABLE_DATA,
      currentUser: GETTER_AUTH_CURRENT_USER,
      userRole: GETTER_AUTH_USER_ROLE,
      publishersData: GETTER_PUBLISHERS_DATA,
    }),

    publishers () {
      return this.publishersData.rows.map(publisher => {
        return {
          id: publisher.id,
          value: publisher.id + ' - ' + publisher.name,
        };
      });
    },

    tableTitle () {
      return () => {
        const primaryAccs = ['admin', 'manager'];

        if (this.userRole === 'publisher') {
          this.column = this.tableColumns.filter(col => col.label !== 'Издатель');
        } else if (primaryAccs.includes(this.userRole)) {
          this.column = this.tableColumns;
        }
      };
    },

    getStatusClass () {
      return (item) => {
        const isAgent = this.currentUser.role === 'client';
        switch (item.status) {
        case 'blocked':
          return 'el-icon-lock';
        case 'pause':
          if (isAgent) {
            return 'el-icon-video-play';
          }
          return 'el-icon-unlock';
        case 'activate':
          if (isAgent) {
            return 'el-icon-video-pause';
          }
          return 'el-icon-unlock';
        default:
          return 'el-icon-unlock';
        }
      };
    },

    getStatusType () {
      return (item) => {
        switch (item.status) {
        case 'new':
          return 'Включить';
        case 'paused':
          return 'Включить';
        case 'active':
          if (['admin', 'manager'].includes(this.currentUser.role)) {
            return 'Заблокировать';
          }
          return 'Выключить';
        case 'blocked':
          return 'Разблокировать';
        default:
          break;
        }
      };
    },
  },

  async created () {
    this.tableTitle();
    await this.getNewPage();
    await this.fetchAllPublishers({
      query: { limit: 0 },
    });
  },

  methods: {
    ...mapActions({
      getApps: ACTION_APPS_GET,
      activateStatus: ACTION_APPS_CHANGE_ACTIVATE,
      blockStatus: ACTION_APPS_CHANGE_BLOCK,
      deleteApp: ACTION_APPS_DELETE,
      fetchAllPublishers: ACTION_PUBLISHERS_GET,
    }),

    async getNewPage (page=1) {
      this.loading = true;
      await this.getApps({
        query: { page },
      });
      this.loading = false;
    },

    async searchApps (data) {

      const query = {
        publisherId: data.id,
        query: data.text,
      };

      await this.getApps({ query });
    },

    mouseOver (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: rgb(148, 147, 150, 0.7); cursor: pointer;';
      }
    },

    mouseOut (row, column, cell) {
      if (column.property === 'options') {
        cell.style = 'background-color: none; color: #409EFF';
      }
    },

    cellClick (row, column) {
      if (column.property === 'options') {
        this.$refs['dropdown' + row.id].visible
          ? this.$refs['dropdown' + row.id].hide()
          : this.$refs['dropdown' + row.id].show();
      }
    },

    routeTo (optionName, id) {
      this.$router.push({ name: 'Apps' + optionName, params: { id } });
    },

    handleEditAdd (row) {
      this.openModal = true;
      this.formData = row;
    },
    async handleChangeStatus (row) {
      const params = { id: row.id };
      if (row.status === 'blocked') {
        await this.activateStatus({
          params,
        });
      } else {
        await this.blockStatus({
          params,
        });
      }
      await this.getNewPage(this.tableData.currentPage);
    },
    async handleDelete (id) {
      const { currentPage, rows } = this.tableData;
      this.$messageBox.confirm(
        'Вы действительно хотите удалить', '', {
          cancelButtonText: 'Нет',
          confirmButtonText: 'Да',
        }).then(async () => {
        await this.deleteApp({
          params: { id },
        });
        if (rows.length - 1) {
          await this.getNewPage(currentPage);
        } else {
          await this.getNewPage(currentPage - 1);
        }
      });
    },
  },
};

</script>

<style lang="scss">
.apps {
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: right;

  .formCreateBtn{
    margin-top: 40px;
  }
  .el-icon-more {
    transform: rotate(90deg);
    cursor: pointer;
  }
}
</style>