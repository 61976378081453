<template>
  <div class="restore-block">
    <h3 class="restore-title">
      Новый пароль
    </h3>
    <div
      v-loading="load"
      element-loading-background="transparent"
    >
      <el-form 
        ref="form"
        :model="restore"
        :rules="rules"
        status-icon 
      >
        <el-form-item
          prop="pass"
          :rules="rules.pass"
          :label="'Пароль'"
        >
          <el-input
            v-model="restore.pass"
            type="password"
            :class="{ 'input-error': isEmailError }"
            placeholder="Введите новый пароль"
          />
        </el-form-item>
        <el-form-item
          prop="checkPass"
          :rules="rules.checkPass"
          :label="'Повторите новый пароль'"
        >
          <el-input
            v-model="restore.checkPass"
            type="password"
            :class="{ 'input-error': isEmailError }"
            placeholder="Повторите новый пароль"
          />
        </el-form-item>
        <el-form-item class="btn-center">
          <el-button 
            type="primary"
            @click="submitForm"
          >
            Сохранить
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>  
</template>

<script>
import { ACTION_AUTH_NEWPASS } from '@/const';
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';

export default {
  components: {},
  mixins: [validationMixin],
  data () {
    const checkPassLength = (rule, value, cb) => value.length < 5 ? cb(new Error('Мин. длина пароля: 5')) : cb();
    const checkSamePass = (rule, value, cb) => value !== this.restore.pass 
      ? cb(new Error('Пароли не совпадают')) : cb();
    return {
      isEmailError: false,
      load: false,
      
      restore: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [
          { required: true, message: 'Заполните поле', trigger: ['blur', 'change'] },
          { validator: checkPassLength, trigger: ['blur', 'change'] },
        ],
        checkPass: [
          { required: true, message: 'Заполните поле', trigger: ['blur', 'change'] },
          { validator: checkSamePass, trigger: ['blur', 'change'] },
        ],
      },
    };
  },

  methods: {
    ...mapActions({
      sendNewPassword: ACTION_AUTH_NEWPASS,
    }),

    async submitForm () {
      await this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.load = true;
          
          const data = {
            password: this.restore.checkPass,
            key: this.$route.query.key,
          };
          const response = await this.sendNewPassword({ data });
      
          if (response.errors) {
            response.errors.forEach(item => {
              this.errorMsg = item.msg;
            });
          }
          const text = !response.errors ? 'Пароль удачно сохранён, вы можете авторизоваться' : '';

          this.$messageBox.alert(
            !response.errors ? text : this.errorMsg || 'Попробуйте ещё раз', '', {
              type: !response.errors ? 'success' : 'error',
              confirmButtonText: 'OK',
              callback: () => {
                this.restore.pass = this.restore.checkPass = '';
                this.load = false;
              },
            });
          response.errors || await this.$router.push({ name: 'Login' });
        }
      }
      );
    },
  },
};

</script>

<style lang="scss">
.restore-block {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(28, 24, 54, 0.12), 0 0 6px rgba(26, 34, 59, 0.04);

  & .restore-title {
    text-align: center;
    letter-spacing: 2px;
    color: #606266;
    text-transform: uppercase;
  }

  & .btn-center {
    display: flex;
    justify-content: center;
  }

}
  .el-message-box__headerbtn {
    display: none;
  }
</style>