<template>
  <el-dialog
    :title=" formData ? 'Обновление Приложения' : 'Cоздание Приложения'"
    :visible="openModal"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      v-loading="Loading"
      :model="form"
      :rules="rules"
      style="position: inherit!important;"
    >
      <el-form-item
        v-if="hasAccess"
        label="Издатель"
        prop="publisherId"
      >
        <el-autocomplete
          v-model="publisherName"
          class="inline-input"
          :fetch-suggestions="querySearch"
          @select="handleSelect"
        />
      </el-form-item>
      <el-form-item
        label="Название"
        prop="name"
      >
        <el-input
          v-model="form.name"
        />
      </el-form-item>
      <el-form-item
        label="Бандл"
        prop="bundle"
      >
        <el-input
          v-model="form.bundle"
        />
      </el-form-item>
      <el-form-item
        v-if="formData"
        label="Секретный ключ"
        prop="secretKey"
      >
        <el-input
          :value="formData.secretKey"
          disabled
          :style="{ cursor: 'text' }"
        />
      </el-form-item>
      <el-form-item
        class="update-button-item"
      >
        <el-button
          v-if="formData"
          type="primary"
          @click="updateApps"
        >
          Сохранить
        </el-button>
        <el-button
          v-if="!formData"
          type="primary"
          @click="createApps"
        >
          Создать
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_APPS_UPDATE,
  ACTION_APPS_CREATE,
  GETTER_AUTH_CURRENT_USER,
  ACTION_APPS_GET,
  GETTER_PUBLISHERS_DATA,
  GETTER_AUTH_USER_ROLE,
  GETTER_APP_TABLE_CATEGORIES,
  GETTER_APP_TABLE_DATA,
} from '@/const';

export default {
  props: {
    openModal: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      treeProps: {
        label: 'name',
        children: 'children',
      },
      value: '',
      Loading: false,
      publisherName: '',
      form: {
        publisherId: null,
        name: '',
        bundle: '',
      },
      publishers: [],
      selectedCategory: 'Категории',
      rules: {
        bundle: [
          { required: true, message: 'Заполните поле' },
        ],
        publisherId: [
          { required: true, message: 'Заполните поле' },
        ],
        name: [
          { required: true, message: 'Заполните поле' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      getPublishersData: GETTER_PUBLISHERS_DATA,
      currentUser: GETTER_AUTH_CURRENT_USER,
      categories: GETTER_APP_TABLE_CATEGORIES,
      userRole: GETTER_AUTH_USER_ROLE,
      getTableData: GETTER_APP_TABLE_DATA,
    }),

    hasAccess () {
      return this.userRole !== 'publisher';
    },
  },

  created () {
    
    if (this.formData) {
      const { publisher } = this.formData;
      this.form = { ...this.formData };
      this.publisherName = this.formData.publisher.name;
      this.form.publisherId = publisher.id;
    } else if (!this.formData && !this.hasAccess) {
      const publisherId = this.currentUser.publishers[0].id;
      this.form.publisherId = publisherId;
    }
    if (this.hasAccess) {
      this.publishers = this.getPublishersData.rows.map(item => {
        return {
          id: item.id.toString(),
          value: item.name,
        };
      });
    }
  },

  methods: {
    ...mapActions({
      updateApp: ACTION_APPS_UPDATE,
      createApp: ACTION_APPS_CREATE,
      getApps: ACTION_APPS_GET,
    }),

    querySearch (queryString, cb) {
      const publishers = this.publishers;
      const results = queryString ? publishers.filter(this.createFilter(queryString)) : publishers;
      cb(results);
    },

    createFilter (queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleSelect (item) {
      this.form.publisherId = parseInt(item.id);
    },

    async updateApps () {
      this.Loading = true;
      const data = { ...this.form };
      const params = { id: this.formData.id };

      delete data.lineItems;
      delete data.reports;
      delete data.bidfloor;
      delete data.publisher;

      const response = await this.updateApp({
        params,
        data,
      });

      await this.check(response);

      this.Loading = false;
    },

    async createApps () {
      this.$refs['form'].validate( async (valid) => {
        if (valid) {
          this.Loading = true;
          const data = { ...this.form };

          const response = await this.createApp({
            data,
          });
          
          await this.check(response);

          this.Loading = false;
        }});
    },

    async check (response) {
      const { currentPage } = this.getTableData;

      if (response.data.errors) {
        await this.$messageBox.alert(
          'Что-то пошло не так', 'Ошибка', {
            confirmButtonText: 'Ok',
          });
      } else {
        await this.getApps({
          query: { page: currentPage },
        });
        this.$emit('close');
      }
    },
  },
};

</script>

<style lang="scss">
.el-form {
  overflow: hidden;
  max-height: 450px;
  padding-right: 15px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}
</style>