<template>
  <el-container>
    <div class="edit-block">
      <div 
        v-loading="load"
        class="loader"
        element-loading-background="transparent"
      >
        <el-form 
          autocomplete="off"
          width="460px"
          @submit.prevent
        >
          <el-form-item 
            label="Почта пользователя"
          >
            <el-input 
              v-model="form.email"
              type="text"
              autocomplete="off"
              :class="{ 'form-name--error': $v.form.email.$error }"
              placeholder="Введите новую почту"
            />
          </el-form-item>
          <el-form-item 
            label="Пароль"
          >
            <el-input
              v-model="form.pass"
              type="password"
              autocomplete="off"
              placeholder="Введите новый пароль"
              show-password
              :class="{ 'form-passwordF--error': $v.form.pass.$error }"
            />
          </el-form-item>
          <el-form-item 
            label="Подтвердите пароль"
          >
            <el-input 
              v-model="form.checkPass" 
              type="password"
              autocomplete="off"
              placeholder="Повторите новый пароль"
              show-password
              :class="{ 'form-passwordS--error': $v.form.checkPass.$error }"
            />
          </el-form-item>
          <el-form-item class="btn-center"> 
            <el-button 
              type="primary" 
              :disabled="!form.email.length"
              @click="submitForm"
            >
              Сохранить
            </el-button>
          </el-form-item>
        </el-form>  
      </div>
    </div>
  </el-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import {
  GETTER_AUTH_CURRENT_USER,
  ACTION_AUTH_EDIT,
  ACTION_AUTH_CURRENT_USER,
  GETTER_AUTH_USER_ACCESS,
} from '@/const';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Edit',
  mixins: [validationMixin],
  data () {
    return {
      form: {
        email: '',
        pass: '',
        checkPass: '',
      },
      newPassword: '',
      load: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(2),
      },
      pass: { 
        minLength: minLength(5),
      },
      checkPass: {  
        sameAsPassword: sameAs('pass'),
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: GETTER_AUTH_CURRENT_USER,
      getUserAccess: GETTER_AUTH_USER_ACCESS,
    }),
    userAccess () {
      return this.getUserAccess.filter(item => !item.subMenu);
    },
  },
  methods: {
    ...mapActions({
      edit: ACTION_AUTH_EDIT,
      getCurrentUser: ACTION_AUTH_CURRENT_USER,
    }),
    async submitForm () {
      this.$v.$touch();
      if (this.form.pass === this.form.checkPass) {
        this.newPassword = this.form.checkPass;
      }
      if (!this.$v.form.$invalid) {
        this.load = true;
        const { updated } = await this.edit({
          params: { id: this.currentUser.id },
          data: {
            ...this.currentUser,
            email: this.form.email,
            password: this.newPassword,
          },
        });
        this.load = false;
        await this.$messageBox.alert(
          updated
            ? 'Данные успешно изменены'
            : 'Что-то пошло не так, попробуйте чуть позже', '', {
            type: updated ? 'success' : 'error',
            confirmButtonText: 'OK',
            callback: async () => {
              const userRes = await this.getCurrentUser();
              if (userRes.status !== 200) {
                await this.$router.push({ name: 'Login' });
              }
              await this.$router.push(this.userAccess[0].route);
            },
          });
      }
    },
  },
};

</script>

<style lang="scss">
.edit-block {
  width: 400px;
  padding: 30px;

  & .btn-center {
    display: flex;
    justify-content: center;
  }

  & .form-name--error, & .form-passwordF--error, & .form-passwordS--error {
    display: flex;
    flex-direction: column;
    position: relative;
    input {
      border: 1px solid red;
    }
  }
  .form-name--error::after {
    position: absolute;
    content: "Больше 2 символов";
    top: 80%;
    left: 10px;
    color: red;
    font-size: 14px;
  }
  .form-passwordF--error::after {
    position: absolute;
    content: "Больше 5 символов";
    top: 80%;
    left: 10px;
    color: red;
    font-size: 14px;
  }
  .form-passwordS--error::after {
    position: absolute;
    content: "Пароли должны совпадать";
    top: 80%;
    left: 10px;
    color: red;
    font-size: 14px;
  }
}
</style>