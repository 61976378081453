<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
body {
  font-family: 'Roboto';
  margin: 0;
  color: #606266;
}
</style>
