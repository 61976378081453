import { 
  Header,
  Container,
  Button,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  MessageBox,
  Message,
  Dialog,
  Loading,
  Aside,
  Menu,
  Popover,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Col,
  Tree,
  Autocomplete,
  Upload,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Notification,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
} from 'element-ui';

export function initElement (Vue) {
  Vue.use(Header);
  Vue.use(Breadcrumb);
  Vue.use(BreadcrumbItem);
  Vue.use(Container);
  Vue.use(Button);
  Vue.use(Form);
  Vue.use(Dropdown);
  Vue.use(DropdownMenu);
  Vue.use(DropdownItem);
  Vue.use(FormItem);
  Vue.use(Input);
  Vue.use(Checkbox);
  Vue.use(CheckboxGroup);
  Vue.use(Dialog);
  Vue.use(Loading);
  Vue.use(Tree);
  Vue.use(Aside);
  Vue.use(Popover);
  Vue.use(Menu);
  Vue.use(Submenu);
  Vue.use(MenuItemGroup);
  Vue.use(MenuItem);
  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Select);
  Vue.use(Option);
  Vue.use(DatePicker);
  Vue.use(Col);
  Vue.use(Autocomplete);
  Vue.use(Upload);
  Vue.use(Pagination);
  Vue.use(Tag);

  Vue.prototype.$message = Message; // TODO:
  Vue.prototype.$messageBox = MessageBox;
  Vue.prototype.$notify = Notification;
}