import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import systemReports from './modules/systemReports';
import publishers from './modules/publishers';
import users from './modules/users';
import apps from './modules/apps';
import axios from 'axios';
import { getUrl } from '@/utils';
import router from '@/router';
import { VueInstance } from '@/main';

import { BASE_URL } from '@/const';

const api = axios.create({
  baseURL: BASE_URL,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebarIsOpen: true,
  },
  mutations: {},
  actions: {
    http: async ({ commit }, { url, method, params = {}, data = {}, commitName, query={} }) => {
      url = getUrl(url, params);
      const token = localStorage.getItem('token') || '';
      let response = null;
      try {
        switch (method) {
        case 'GET':
          response = await api({
            method: 'GET',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'POST':
          response = await api({
            method: 'POST',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'PUT':
          response = await api({
            method: 'PUT',
            url,
            data,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'DELETE':
          response = await api({
            method: 'DELETE',
            url,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        case 'PATCH':
          response = await api({
            method: 'PATCH',
            url,
            data,
            params: query,
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          break;
        default:
          break;
        }
      } catch (res) {
        if (res.response && res.response.status === 401) {
          localStorage.removeItem('token');
          router.push('/login').catch(() => {});
        }
        response = res.response;
        // TODO check on 401 error
      }
      if (response && response.data && response.data.errors && response.data.errors.length) {
        let text = '';
        if (response.status === 500) {
          text = 'Ошибка сервера';
        }
        if (text) {
          VueInstance.$notify.error({
            title: 'Ошибка',
            message: text,
            dangerouslyUseHTMLString: true,
            position: 'top-left',
          });
        }
      }
      if (commitName && response) {
        commit(commitName, response.data);
      }
      return response;
    },
  },
  modules: {
    auth,
    systemReports,
    apps,
    users,
    publishers,
  },
});
