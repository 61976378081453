<template>
  <div 
    ref="report"
    class="report-table"
  >
    <el-container>
      <export-excel
        :data="reportData.excelData"
        name="filename"
        class="el-icon-download btn"
      >
        Скачать таблицу
      </export-excel>
      <el-table
        v-if="reportData.header && reportData.rows"
        :data="reportData.rows"
        stripe
        :max-height="maxHeight"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column
          v-for="(item, index) of reportData.header"
          :key="index"
          :prop="item.key"
          :label="item.name"
          :min-width="item.width"
          align="center"
        />
      </el-table>
      <el-pagination
        v-if="reportData && reportData.rows_count > reportData.total"
        class="report-table-pagination"
        background
        layout="prev, pager, next"
        :total="100"
        @current-change="getNewPage"
      />
    </el-container>
  </div>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      maxHeight: 'auto',
    };
  },

  mounted () {
    this.maxHeight = this.$refs.report.parentNode.clientHeight - 150;
  },

  methods: {
    getNewPage (page) {
      this.$emit('goToPage', page);
    },

    getSummaries () {
      return this.reportData.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.report-table {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: right;

  .el-table {
    border: 1px solid #EBEEF5;
    width: 100%;
  }

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: inherit;
    right: 0;
    left: auto;
    padding: 0 15px 15px;
    justify-content: flex-end;
    align-items: flex-end;
    top: 25px;

    .btn {
      width: 150px;
      height: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      background-color: #409EFF;
      margin:  0 0 15px 0;
    }
  }

  .report-table-pagination {
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
}
</style>

<style lang="scss">
.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
    background-color: #E7EEF4;;
  }

  &::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 6px;
  }

  &::before {
    height: 0;
  }
}

.el-table__footer-wrapper {
  font-style: oblique;
}

table {
  margin: 0 !important;
}
</style>