<template>
  <el-form
    v-if="labelsAndPlaceholders"
    label-position="top"
    class="app-filter-form"
    :inline="true"
  >
    <el-form-item
      v-show="parentName !== 'users'"
      :label="labelsAndPlaceholders.autocompletesLabel"
    >
      <el-autocomplete
        v-model="searchText"
        class="inline-input"
        :placeholder="labelsAndPlaceholders.autocompletesPlaceholder"
        :fetch-suggestions="querySearchById"
        @select="(item) => query.id = item.id"
        @focus="isDisableSearchBtn = false"
      />
    </el-form-item>
    <el-form-item
      :label="labelsAndPlaceholders.inputLabel"
    >
      <el-input
        v-model="query.text"
        type="text"
        :placeholder="labelsAndPlaceholders.inputPlaceholder"
        @focus="isDisableSearchBtn = false"
        @input="enableSearchBtn"
        @keydown.enter.native.prevent="submitForm"
      />
    </el-form-item>
    
    <el-form-item>
      <el-button
        class="btns"
        type="primary"
        :disabled="isDisableSearchBtn"
        @click="submitForm"
      >
        Поиск
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button
        class="btns"
        type="primary"
        @click="resetForm"
      >
        Сбросить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  components: {},
  props: {
    parentName: {
      type: String,
      default: '',
    },
    autocompletesArray: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      searchText: null,
      query: {
        id: null,
        text: null,
      },
      isDisableSearchBtn: false,
    };
  },
  computed: {
    labelsAndPlaceholders () {
      return {
        users: {
          inputLabel: 'Почта',
          inputPlaceholder: 'Введите почту',
        },
        apps: {
          autocompletesLabel: 'Издатель',
          autocompletesPlaceholder: 'Выберите издатель',
          inputLabel: 'Название',
          inputPlaceholder: 'Название приложения',
        },
      }[this.parentName];
    },
  },
  methods: {
    querySearchById (queryString, cb) {
      const results = queryString ? this.autocompletesArray.filter(item => {
        const value = item.value.toLowerCase();
        return value.indexOf(queryString.toLowerCase()) !== -1;
      }) : this.autocompletesArray;
      cb(results);
    },

    async submitForm () {
      this.isDisableSearchBtn = true;
      if (this.query.text || this.query.id) {
        this.$emit('search', this.query);
      }
    },

    resetForm () {
      this.isDisableSearchBtn = false;
      this.query.id = this.query.text = this.searchText = null;
      this.$emit('reset');
    },

    enableSearchBtn () {
      if (this.isDisableSearchBtn) {
        this.isDisableSearchBtn = false;
      }
    },
  },
};
</script>
<style lang="scss">
.app-filter-form {
  position: absolute;
  top: 35px;
  right: 110px;
  text-align: left;
  margin-bottom: 40px;
  padding: 0 !important;

  & .el-form-item__label {
    line-height: 0;
  }
  .btns {
    margin-top: 20px;
  }
  .apps-add-btn {
    margin-left: 30px;
  }
}
</style>