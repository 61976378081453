import {
  MUTATION_SYSTEM_CURRENT_REPORT_SET,
  URL_SYSTEM_REPORT_GET,
  ACTION_ROOT_REQUEST,
} from '@/const';

const state = {
  currentReport: {},
};

const getters = {
  getCurrentReport (state) {
    return state.currentReport;
  },
};

const mutations = {
  setCurrentReport (state, response) {
    state.currentReport = response;
  },
};

const actions = {
  fetchCurrentReport: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_SYSTEM_REPORT_GET,
      commitName: MUTATION_SYSTEM_CURRENT_REPORT_SET,
    }, { root: true });
    return res;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};