import {
  ACTION_ROOT_REQUEST,
  MUTATION_AUTH_LOGIN,
  MUTATION_AUTH_SIGNOUT,
  URL_AUTH_LOGIN,
  URL_AUTH_CONFIRM,
  URL_GET_CURRENT_USER,
  MUTATION_AUTH_SET_USER,
  URL_AUTH_SIGHOUT,
  URL_AUTH_RESTORE,
  URL_AUTH_EDIT,
} from '../../const';

const ADMIN = 'admin';
const MANAGER = 'manager';
const PUBLISHER = 'publisher';

let access = [
  {
    title: 'Приложения',
    accessUsers: [ADMIN, MANAGER, PUBLISHER],
    route: '/apps',
  },
  {
    title: 'Настройки',
    accessUsers: [ADMIN],
    subMenu: [
      {
        title: 'Издатели',
        route: '/publishers',
      },
      {
        title: 'Пользователи',
        route: '/users',
      },
    ],
  },
  // {
  //   title: 'Отчёты',
  //   accessUsers: [ADMIN, MANAGER, PUBLISHER],
  //   route: '/reports',
  // },
];

const state = {
  user: null,
};

const getters = {

  currentUser (state) {
    return state.user;
  },

  userRole (state) {
    switch (state.user.role) {
    case ADMIN:
      return ADMIN;
    case MANAGER:
      return MANAGER;
    case 'client':
      return PUBLISHER;
    default:
      break;
    }
  },

  getUserAccess (state) {
    if (state.user) {
      let role = state.user.role;
      if (role === 'client') {
        if (state.user.publishers.length) {
          role = PUBLISHER;
        }
      }
      return access.filter(item => item.accessUsers.includes(role));
    }
    return [];
  },
};

const mutations = {
  login (state, response ) {
    const token = response.token;
    if (token) {
      localStorage.setItem('token', token);
    }
  },

  setCurrentUser (state, user) {
    state.user = user;
  },

  logout (state) {
    if (state.user) {
      localStorage.removeItem('token');
      state.user = null;
    }
  },

  edit (state, data) {
    state.user.name = data.name;
    if (data.password) {
      state.user.password = data.password;
    }
  },
};

const actions = {
  loginData: async ({ dispatch }, { data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      url: URL_AUTH_LOGIN,
      data,
      commitName: MUTATION_AUTH_LOGIN,
    }, { root: true });
    return response;
  },

  sendEmail: async ({ dispatch }, { query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_AUTH_RESTORE,
    }, { root: true });
    return response;
  },

  sendNewPassword: async ({ dispatch }, { data }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      url: URL_AUTH_RESTORE,
    }, { root: true });
    if (response.data.errors) {
      return response.data;
    }
    return response;
  },

  verify: async (ctx, { query }) => {
    const res = await ctx.dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_AUTH_CONFIRM,
    }, { root: true });
    return res;
  },

  getCurrentUser: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      url: URL_GET_CURRENT_USER,
      commitName: MUTATION_AUTH_SET_USER,
    }, { root: true });
    return res;
  },

  signOut: async ({ dispatch }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      url: URL_AUTH_SIGHOUT,
      commitName: MUTATION_AUTH_SIGNOUT,
    }, { root: true });
    return res;
  },

  edit: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_AUTH_EDIT,
    }, { root: true });
    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
