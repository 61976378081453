//URL's
export const BASE_URL = 'https://api.dmp.greengreystudio.com/api';

export const URL_AUTH_LOGIN = '/users/signin';
export const URL_AUTH_CONFIRM = '/users/confirm-email';
export const URL_AUTH_RESTORE = '/users/restore';
export const URL_GET_CURRENT_USER = '/users/me';
export const URL_AUTH_SIGHOUT = 'users/signout';
export const URL_AUTH_EDIT = '/users/:id';

export const URL_APPS_GET_CREATE = '/apps';
export const URL_APPS_GET_UPDATE = '/apps/:id';
export const URL_APPS_STATUS_PAUSE = '/apps/:id/pause';
export const URL_APPS_CHANGE_ACTIVATE = '/apps/:id/activate';
export const URL_APPS_CHANGE_BLOCK = '/apps/:id/block';
export const URL_APPS_GET_REPORT = '/apps/:id/report';
export const URL_APPS_GET_EVENTS = '/apps/:id/events';
export const URL_APPS_CREATE_EVENT = '/apps/:app_id/events';
export const URL_APPS_UPDATE_DELETE_EVENT = '/apps/:app_id/events/:id';
export const URL_APPS_CREATE = '/apps';

export const URL_SYSTEM_REPORT_GET = '/report/system';

export const URL_USERS_GET = '/users/profile/:id';
export const URL_USERS_UPDATE = '/users/:id';
export const URL_USERS_DELETE_CURRENT = '/users/:id';
export const URL_USERS_GET_CREATE = '/users';
export const URL_USERS_BLOCK = '/users/:id/activate';
export const URL_USERS_ACTIVATE = '/users/:id/block';

export const URL_PUBLISHERS_GET_POST = '/publishers';
export const URL_PUBLISHERS_DELETE_PUT = '/publishers/:id';
export const URL_PUBLISHERS_USERS_GET_POST = '/publishers/:id/users';
export const URL_PUBLISHERS_USERS_DELETE = '/publishers/:publishers_id/users/:id';

//GETTERS
export const GETTER_AUTH_CURRENT_USER = 'auth/currentUser';
export const GETTER_AUTH_USER_ACCESS = 'auth/getUserAccess';
export const GETTER_AUTH_USER_ROLE = 'auth/userRole';

export const GETTER_APP_TABLE_COLUMNS = 'apps/getTableColumns';
export const GETTER_APP_EVENTS_TABLE_COLUMNS = 'apps/getAppsEventTableColumns';
export const GETTER_APP_TABLE_DATA = 'apps/getTableData';
export const GETTER_APP_EVENTS_TABLE_DATA = 'apps/getEventsData';
export const GETTER_APP_REPORT = 'apps/appsReport';
export const GETTER_APP_TABLE_REPORT_COLUMNS = 'apps/reportTablecolumns';
export const GETTER_APP_TABLE_CATEGORIES = 'apps/categories';

export const GETTER_SYSTEM_REPORT = 'systemReports/getCurrentReport';

export const GETTER_PUBLISHERS_DATA = 'publishers/getPublishersData';
export const GETTER_PUBLISHERS_TABLE = 'publishers/getPublishersColumns';
export const GETTER_PUBLISHERS_USERS_DATA = 'publishers/getPublishersUser';
export const GETTER_PUBLISHERS_USERS_TABLE = 'publishers/getPublishersUsersColumns';

export const GETTER_USERS_DATA = 'users/getUsersData';
export const GETTER_USERS_TABLE = 'users/getUsersColumns';
//MUTATIONS
export const MUTATION_AUTH_SET_USER = 'auth/setCurrentUser';
export const MUTATION_AUTH_LOGIN = 'auth/login';
export const MUTATION_AUTH_SIGNOUT = 'auth/logout';
export const MUTATION_AUTH_EDIT = 'auth/edit';

export const MUTATION_APPS_SET = 'apps/setApps';
export const MUTATION_FILTERED_APPS_SET = 'apps/setFilteredApps';
export const MUTATION_APPS_ADD = 'apps/addApp';
export const MUTATION_APPS_CHANGE = 'apps/changeApp';
export const MUTATION_APPS_DELETE = 'apps/removeApp';
export const MUTATION_APPS_CHANGE_STATUS = 'apps/changeAppStatus';
export const MUTATION_APPS_SET_REPORT = 'apps/setAppsReport';
export const MUTATION_APPS_EVENTS_SET = 'apps/setAppsEvents';
export const MUTATION_APPS_SET_CATEGORIES = 'apps/setCategories';

export const MUTATION_SYSTEM_CURRENT_REPORT_SET = 'systemReports/setCurrentReport';

export const MUTATION_PUBLISHERS_SET = 'publishers/setPublishers';
export const MUTATION_PUBLISHERS_DELETE = 'publishers/removePublishers';
export const MUTATION_PUBLISHERS_UPDATE = 'publishers/updatePublishers';
export const MUTATION_PUBLISHERS_USERS_SET = 'publishers/setPublishersUser';
export const MUTATION_PUBLISHERS_USERS_ADD = 'publishers/addNewPublishersUsers';
export const MUTATION_PUBLISHERS_USERS_REMOVE = 'publishers/removePublishersUsers';

export const MUTATION_USERS_SET = 'users/setUsers';
export const MUTATION_USERS_CREATE = 'users/addUser';
export const MUTATION_USERS_UPDATE = 'users/updateUser';
export const MUTATION_USERS_DELETE = 'users/deleteUser';
export const MUTATION_USERS_BLOCK = 'users/blockUser';
export const MUTATION_USERS_ACTIVATE = 'users/activateUser';
//ACTIONS
export const ACTION_ROOT_REQUEST = 'http';

export const ACTION_AUTH_LOGIN = 'auth/loginData';
export const ACTION_AUTH_EMAIL ='auth/sendEmail';
export const ACTION_AUTH_NEWPASS = 'auth/sendNewPassword';
export const ACTION_AUTH_VERIFY = 'auth/verify';
export const ACTION_AUTH_CURRENT_USER = 'auth/getCurrentUser';
export const ACTION_AUTH_SIGHOUT = 'auth/signOut';
export const ACTION_AUTH_EDIT = 'auth/edit';

export const ACTION_APPS_GET = 'apps/getApps';
export const ACTION_APP_GET = 'apps/getApp';
export const ACTION_APPS_CREATE = 'apps/createApp';
export const ACTION_APPS_UPDATE = 'apps/updateApp';
export const ACTION_APPS_DELETE = 'apps/deleteApp';
export const ACTION_APPS_STATUS_PAUSE = 'apps/changeStatusPause';
export const ACTION_APPS_CHANGE_ACTIVATE = 'apps/changeStatusActivate';
export const ACTION_APPS_CHANGE_BLOCK = 'apps/changeStatusBlock';
export const ACTION_APPS_GET_REPORT = 'apps/getAppsReport';
export const ACTION_APPS_GET_EVENTS = 'apps/getAppsEvents';
export const ACTION_APPS_EVENT_CREATE = 'apps/createAppsEvent';
export const ACTION_APPS_EVENT_UPDATE = 'apps/updateAppsEvent';
export const ACTION_APPS_EVENT_DELETE = 'apps/deleteAppsEvent';

export const ACTION_SYSTEM_CURRENT_REPORT_GET = 'systemReports/fetchCurrentReport';

export const ACTION_PUBLISHERS_GET = 'publishers/fetchAllPublishers';
export const ACTION_PUBLISHER_GET = 'publishers/fetchPublisher';
export const ACTION_PUBLISHERS_REMOVE = 'publishers/removePublishers';
export const ACTION_PUBLISHERS_UPDATE = 'publishers/updatePublishers';
export const ACTION_PUBLISHERS_CREATE = 'publishers/createPublishers';
export const ACTION_PUBLISHERS_USERS_GET = 'publishers/fetchAllUsers';
export const ACTION_PUBLISHERS_USERS_POST = 'publishers/addNewPublishersUsers';
export const ACTION_PUBLISHERS_USERS_DELETE = 'publishers/removePublishersUsers';

export const ACTION_USERS_GET_USERS = 'users/getAllUsers';
export const ACTION_USERS_GET_USER = 'users/getUser';
export const ACTION_USERS_CREATE = 'users/createUser';
export const ACTION_USERS_UPDATE = 'users/updateUser';
export const ACTION_USERS_DELETE = 'users/deleteUser';
export const ACTION_USERS_ACTIVATE = 'users/blockUser';
export const ACTION_USERS_BLOCK = 'users/activateUser';