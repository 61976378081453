<template>
  <div class="basic">
    <Header
      v-if="currentUser"
      :state-arrow="$store.state.sidebarIsOpen"
    />
    <div class="content">
      <transition
        name="list"
      >
        <Sidebar
          v-show="$store.state.sidebarIsOpen && currentUser"
          class="sidebarAnimation"
        />
      </transition>
      <el-breadcrumb
        separator="/"
      >
        <el-breadcrumb-item
          v-for="(item) of routes"
          :key="item"
          :to="pushUrl(item)"
        >
          {{ names[item] }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <router-view
        v-if="currentUser"
      />
    </div>
  </div>
</template>

<script>
import Sidebar from '../views/Sidebar';
import Header from '../views/Header';
import { mapActions, mapGetters } from 'vuex';
import {
  ACTION_AUTH_CURRENT_USER,
  GETTER_AUTH_CURRENT_USER,
  ACTION_PUBLISHER_GET,
  GETTER_AUTH_USER_ACCESS,
  ACTION_APP_GET,
} from '@/const';
import { names } from '@/const/breadCrumbs';

export default {
  components: {
    Sidebar,
    Header,
  },
  data () {
    return {
      isOpen: true,
      routes: [],
      routeName: '',
      names: null,
    };
  },

  computed: {
    ...mapGetters({
      getUserAccess: GETTER_AUTH_USER_ACCESS,
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),
  },

  watch: {
    $route () {
      this.getRoute();
    },
  },

  async created () {
    this.names = { ...names };
    const res = await this.getCurrentUser();
    if (res.status !== 200) {
      await this.$router.push({ name: 'Login' });
    }

    const path = this.getUserAccess[0].route;
    if (this.$router.currentRoute.path !== path) {
      await this.$router.push(path);
    }

    await this.getRoute();
  },

  methods: {
    ...mapActions({
      getCurrentUser: ACTION_AUTH_CURRENT_USER,
      fetchPublisher: ACTION_PUBLISHER_GET,
      fetchApp: ACTION_APP_GET,
    }),

    pushUrl (item) {
      const index = this.$route.path.indexOf(item) + item.length;
      return this.$route.path.slice(0, index);
    },

    async getRoute () {
      const { path } = this.$route;
      const routePath = path.split('/');
      this.routes = routePath.filter((item) => {
        if (names[item]) {
          return item;
        }
      });

      const parentRoute = this.$route.fullPath.split('/')[1];
      if (this.$route.params.id) {
        if (parentRoute === 'publishers') {
          const { data } = await this.fetchPublisher({
            params: { id: this.$route.params.id },
          });
          this.names.publishers = `Издатель: ${data.name}`;
        } else if (parentRoute === 'apps') {
          const { data } = await this.fetchApp({
            params: { id: this.$route.params.id },
          });
          this.names.apps = `Приложение: ${data.name}`;
        }
      } else {
        this.names = { ...names };
      }
    },
  },
};
</script>

<style lang="scss">
.basic {
  width: 100%;
  .content {
    display: flex;
    position: relative;

    .el-breadcrumb {
      z-index: 2;
      position: absolute;
      left: 300px;
      top: 35px;
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 0.3s;
  }

  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(-300px) ;
  }

}
</style>
