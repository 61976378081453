<template>
  <div class="header-block">
    <el-header class="header">
      <div class="icon-block">
        <transition
          v-if="stateArrow"
          name="list"
        />
        <div
          :class="{'is-active' : stateArrow}"
          class="icon-block-content"
          @click="showSideBar"
        />
      </div>
      <div class="profile">
        <el-dropdown
          trigger="click"
          style="position: relative !important;"
          @command="handleCommand"
        >
          <span
            v-if="currentUser"
            class="el-dropdown-link"
          >
            {{ `${currentUser.name ? currentUser.name + ' | ' : ''} ${currentUser.email}` }}
            <i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu
            class="el-dropdown-menu"
          >
            <el-dropdown-item
              command="clickToProfile"
            >
              Профиль
            </el-dropdown-item>
            <el-dropdown-item
              class="logout"
              command="showDialog"
            >
              Выйти из профиля
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-dialog
      title="Выход из системы"
      :visible.sync="dialogVisible"
      width="400px"
    >
      <span>Вы дейстивтельно хотите выйти?</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">Отмена</el-button>
        <el-button
          type="primary"
          @click="logout"
        >
          Подтвердить
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ACTION_AUTH_SIGHOUT, GETTER_AUTH_CURRENT_USER } from '@/const';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Header',
  props: {
    stateArrow: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: GETTER_AUTH_CURRENT_USER,
    }),
  },
  methods: {
    ...mapActions({
      signOut: ACTION_AUTH_SIGHOUT,
    }),

    handleCommand (command) {
      if (command === 'clickToProfile' ) {
        this.$router.push('/profile');
      } else if (command === 'showDialog') {
        this.dialogVisible = true;
      }
    },

    showSideBar () {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },

    async logout () {
      this.dialogVisible = false;
      const response = await this.signOut();
      if (response.status === 200) {
        this.$router.push('/login');
      }
    },
  },

};
</script>

<style lang="scss" scoped>
$buttonWidth: 30px;
$buttonHeight: 15px;
$buttonColor: darken(grey, 15%);
$lineThickness: 4px;
$transitionSpeed: .25s;
$transitionEasing: ease-in-out;

.header-block {
  width: 100%;
  & .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;
    box-shadow: 0 0 16px rgba(28, 24, 54, 0.0), 0 0 6px rgba(26, 34, 59, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;

    & .icon-block {
      display: flex;
      width: 210px;
      color: white;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      position: relative;

    & .icon-block-content {
      position: relative;
      display: block;
      width: $buttonWidth;
      height: $buttonHeight;
      cursor: pointer;
      background: transparent;
      border-top: $lineThickness solid;
      border-bottom: $lineThickness solid;
      color: $buttonColor;
      font-size: 0;
      margin-left: 10px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: $lineThickness;

        position: absolute;
        top: 50%;
        left: 50%;

        background: currentColor;

        transform: translate(-50%, -50%);
      } 
    }

    & .icon-block-content:hover {
      color: lighten($buttonColor, 35%);
    }

    & .is-active {
      border-color: transparent;
      position: absolute;
      left: 100%;

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    }
    .profile{
      margin: 0;
      padding: 0;
      position: relative !important;
    }
    & .logout {
      margin-top: 10px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #000;
      max-width: 250px;
      padding: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .el-icon-arrow-down {
      content: "\e6df";
      font-size: 12px;
    }
    
    .el-dropdown-menu {
      position: absolute;
      top: 50px;
      left: auto !important;
      right: 13px !important;
      padding-right: 20px;
      width: 15%;
    }
  }

  .list-enter-active, .list-leave-active {
    transition: all 0.3s;
  }
  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(-20%) ;
  }

}

</style>