import Vue from 'vue';
import App from './App.vue';
import store from './store';
import { initElement } from './modules/Element_io';
import router from './router';
import './assets/css/app.scss';
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
import excel from 'vue-excel-export';

locale.use(lang);

initElement(Vue);
Vue.use(excel);
Vue.config.productionTip = false;

export const VueInstance = new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
