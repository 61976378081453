import {
  ACTION_ROOT_REQUEST,
  URL_APPS_GET_CREATE,
  MUTATION_APPS_SET,
  URL_APPS_STATUS_PAUSE,
  URL_APPS_CHANGE_ACTIVATE,
  URL_APPS_CHANGE_BLOCK,
  MUTATION_APPS_SET_REPORT,
  URL_APPS_GET_REPORT,
  URL_APPS_GET_EVENTS,
  MUTATION_APPS_EVENTS_SET,
  URL_APPS_CREATE_EVENT,
  URL_APPS_UPDATE_DELETE_EVENT,
  URL_APPS_GET_UPDATE,
} from '@/const';

const state = {
  tableData: {
    rows: [],
  },
  currentApp: {},
  eventsData: {},

  reportTableColumns: [
    {
      prop: 'date',
      label: 'Дата',
      width: 10,
    },
    {
      prop: 'imps',
      label: 'Показы',
      width: 12,
    },
    {
      prop: 'budget',
      label: 'Бюджет',
      width: 10,
    },
    {
      prop: 'ev_start',
      label: 'Старт',
      width: 10,
    },
    {
      prop: 'ev_first_quartile',
      label: 'Показано 25%',
      width: 10,
    },
    {
      prop: 'ev_midpoint',
      label: 'Показано 50%',
      width: 10,
    },
    {
      prop: 'ev_third_quartile',
      label: 'показано 75%',
      width: 10,
    },
    {
      prop: 'ev_complete',
      label: 'показано 100%',
      width: 10,
      buttons: true,
    },
  ],
  reportsData: {
    rows: [],
  },
};

const getters = {
  getTableColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 35,
        buttons: true,
      },
      {
        prop: 'status',
        label: 'Статус',
        width: 70,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Назавние',
        width: 150,
      },
      {
        prop: 'bundle',
        label: 'Бандл',
        width: 150,
      },
      {
        prop: 'publisher.name',
        label: 'Издатель',
        width: 150,
      },
    ];
    return columns;
  },
  getAppsEventTableColumns () {
    const columns = [
      {
        prop: 'options',
        label: '',
        width: 35,
        buttons: true,
      },
      {
        prop: 'id',
        label: 'ID',
        width: 50,
      },
      {
        prop: 'key',
        label: 'Идентификатор',
        width: 50,
      },
      {
        prop: 'name',
        label: 'Назавние',
        width: 150,
      },
    ];
    return columns;
  },
  getTableData (state) {
    return state.tableData;
  },

  reportTablecolumns () {
    return state.reportTableColumns;
  },

  appsReport (state) {
    return state.reportsData;
  },

  getEventsData (state) {
    return state.eventsData;
  },
};

const mutations = {
  setApps: (state, response) => {
    state.tableData = response;
  },

  updateApp: (state, response) => {
    const index = state.tableData.findIndex(data => {
      return data.id === response.id;
    });
    state.tableData[index] = response;
  },

  removeApp: (state, response) => {
    const index = state.tableData.findIndex(data => {
      return data.id === response.id;
    });
    state.tableData.splice(index, 1);
  },

  changeAppStatus: (state, response) => {
    const index = state.tableData.findIndex(data => {
      return data.id === response.id;
    });
    state.tableData[index].status = response.status;
  },

  setAppsReport: (state, response) => {
    state.reportsData = {
      ...response,
      rows: response.rows.map(row => row.reduce((acc, item, i) => {
        acc[response.header[i].key] = item;
        return acc;
      }, {})),
    };
  },

  setAppsEvents: (state, response) => {
    state.eventsData = { rows: response };
  },
};

const actions = {
  getApps: async ({ dispatch }, { query }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      url: URL_APPS_GET_CREATE,
      commitName: MUTATION_APPS_SET,
    }, { root: true });
    return res.data;
  },

  getApp: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_GET_UPDATE,
    }, { root: true });
    return res;
  },

  updateApp: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PUT',
      data,
      params,
      url: URL_APPS_GET_UPDATE,
    }, { root: true });
    return res;
  },

  createApp: async ({ dispatch }, { data,query }) => {
    const response = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      query,
      url: URL_APPS_GET_CREATE,
    }, { root: true });
    return response;
  },

  deleteApp: async ({ dispatch }, { params }) => {
    await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_APPS_GET_UPDATE,
    }, { root: true });
  },

  changeStatusPause: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_STATUS_PAUSE,
    }, { root: true });
    return res;
  },

  changeStatusActivate: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_ACTIVATE,
    }, { root: true });
    return res;
  },

  changeStatusBlock: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'PATCH',
      params,
      url: URL_APPS_CHANGE_BLOCK,
    }, { root: true });
    return res;
  },

  getAppsReport: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      params,
      url: URL_APPS_GET_REPORT,
      commitName: MUTATION_APPS_SET_REPORT,
    }, { root: true });
    return res;
  },

  getAppsEvents: async ({ dispatch }, { query, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'GET',
      query,
      params,
      url: URL_APPS_GET_EVENTS,
      commitName: MUTATION_APPS_EVENTS_SET,
    }, { root: true });
    return res.data;
  },

  createAppsEvent: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_APPS_CREATE_EVENT,
    }, { root: true });
    return res.data;
  },

  updateAppsEvent: async ({ dispatch }, { data, params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'POST',
      data,
      params,
      url: URL_APPS_UPDATE_DELETE_EVENT,
    }, { root: true });
    return res.data;
  },

  deleteAppsEvent: async ({ dispatch }, { params }) => {
    const res = await dispatch(ACTION_ROOT_REQUEST, {
      method: 'DELETE',
      params,
      url: URL_APPS_UPDATE_DELETE_EVENT,
    }, { root: true });
    return res.data;
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};