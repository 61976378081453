<template>
  <div class="apps-report">
    <el-form
      :inline="true"
      :model="form"
      label-position="top"
      class="apps-report-form"
    >
      <el-form-item label="Выберите отчет">
        <el-select
          v-model="form.report"
          placeholder="Выбрать"
        >
          <el-option
            v-for="item in filter.reports"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Выберите диапазон дат">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="До"
          start-placeholder="Дата начала"
          end-placeholder="Дата окончания" 
        />
      </el-form-item>
      <el-form-item class="apply-btn">
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Применить
        </el-button>
      </el-form-item>
    </el-form>
    <ReportTable 
      :report-columns="reportColumns"
      :report-data="appsReport"
      @goToPage="getNewPage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import {
  ACTION_APPS_GET_REPORT,
  GETTER_APP_REPORT,
  GETTER_APP_TABLE_REPORT_COLUMNS,
} from '@/const';

import ReportTable from '@/components/ReportTable';
export default {
  components: {
    ReportTable,
  },
  data () {
    return {
      filter: {
        date: new Date(),
        reports: ['Отчет 1', 'Отчет 2', 'Отчет 3', 'Отчет 4', 'Отчет 5', 'Отчет 6'],
      },
      form: {
        date: [moment().subtract(1, 'month').format('YYYY, MM, DD'), moment().format('YYYY, MM, DD')],
        report: '',
      },
    };
  },

  computed: {
    ...mapGetters({
      appsReport: GETTER_APP_REPORT,
      reportColumns: GETTER_APP_TABLE_REPORT_COLUMNS,
    }),
  },

  async created () {
    await this.getAppsReport({
      params: { id: this.$route.params.id },
    });
  },

  methods: {
    ...mapActions({
      getAppsReport: ACTION_APPS_GET_REPORT,
    }),
    async onSubmit () {
      await this.getAppsReport({
        params: { id: this.$route.params.id },
        query: {
          date_from: this.form.date[0],
          date_to: this.form.date[1],
          'report-name': this.form.report,
        },
      });
    },

    async getNewPage (page) {
      await this.getAppsReport({
        params: { id: this.$route.params.id },
        query: { page },
      });
    },
  },
};
</script>

<style lang="scss">
.apps-report {
  width: 100%;
  position: relative;
  padding-top: 50px;

  .apps-report-form {
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    top: 50px;
    position: absolute;
    right: 200px;
    z-index: 2;
    
    .el-form-item {
      margin-right: 10px;
      text-align: left;
      &__label {
        line-height: 0;
      }
    }
  }
}
</style>